var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "side-menu" } }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "back-home" },
        [
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("span", [_vm._v("＜ 書籍一覧に戻る")]),
          ]),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c(
            "router-link",
            { staticClass: "link", attrs: { to: { name: "memo" } } },
            [_c("span", { staticClass: "text" }, [_c("p", [_vm._v("すべて")])])]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closePopupMenu,
                  expression: "closePopupMenu",
                },
              ],
              staticClass: "novels-section",
            },
            [
              _c("label", [_vm._v("作品")]),
              _c("sort-icon", {
                attrs: {
                  onClick: _vm.switchFragment,
                  iconSize: 30,
                  fill: "#FFFFFF",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "popup-menu-container" },
            [
              _c("popup-menu", {
                attrs: {
                  isOpen: _vm.showSortPopupMenu,
                  items: _vm.sortTypes,
                  onSelect: _vm.onSelectedItem,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "novels-links" },
            _vm._l(_vm.novels, function (novel) {
              return _c(
                "li",
                { key: novel.novelId },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "memo", query: { novelId: novel.novelId } },
                      },
                    },
                    [
                      _c("div", { staticClass: "link" }, [
                        _c(
                          "div",
                          { staticClass: "book-icon" },
                          [_c("book-icon")],
                          1
                        ),
                        _c("div", { staticClass: "novel-title" }, [
                          _vm._v(" " + _vm._s(novel.title) + " "),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("div", { staticClass: "fixed-button-container" }, [
        _c(
          "button",
          {
            staticClass: "btn-edit-tag",
            on: { click: _vm.onCickEditMemoTagButton },
          },
          [_c("label", [_vm._v("タグを編集する")])]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title-container" }, [
      _c("label", [_vm._v("創作メモ")]),
      _c("hr", { staticClass: "divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }