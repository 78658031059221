var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog overlay",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-window" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _c("header", [_vm._v("メモタグの編集")]),
          _c(
            "div",
            { staticClass: "create-tag-container" },
            [
              _c("round-input", {
                attrs: {
                  placeholder: "新規メモタグ",
                  text: _vm.createMemoTagName,
                  changeText: _vm.onChangeText,
                },
              }),
              _c(
                "button",
                {
                  staticClass: "button",
                  class: {
                    active: _vm.createMemoTagName !== "",
                  },
                  attrs: { disabled: _vm.createMemoTagName === "" },
                  on: { click: _vm.onCreateTagButtonClick },
                },
                [_vm._v(" 新規作成 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tag-list" },
            _vm._l(_vm.memoTags, function (memoTag) {
              return _c(
                "li",
                { key: memoTag.id, staticClass: "tag-row" },
                [
                  _c("remove-circle-icon", {
                    attrs: {
                      fill: "#e87474",
                      onClick: _vm.onDeleteClick,
                      item: memoTag,
                    },
                  }),
                  _vm._v(" " + _vm._s(memoTag.name) + " "),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "dialog-footer" }, [
          _c(
            "button",
            {
              staticClass: "button delete",
              on: { click: _vm.onNegativeButtonClick },
            },
            [_vm._v(" " + _vm._s(_vm.negative) + " ")]
          ),
        ]),
      ]),
      _c("span", { staticClass: "toast", class: { visible: _vm.deleted } }, [
        _vm._v("削除しました"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }